<template>
  <div class="col-10 mr-0 pr-0">
    <table class="table table-bordered table-investment">
      <thead class="th-investment">
        <tr>
          <th width="29.5%">CATEGORIE 1</th>
          <th width="14%">CATEGORIE 1.2</th>
          <th width="16%">FONCTION</th>
          <th class="text-center"
              width="13%">NET MENSUEL</th>
          <th class="text-center"
              width="10%">CARBURANT</th>
          <th class="text-center"
              width="10%">TELEPHONE</th>
          <th class="text-center "
              width="7.5%">NB AGENT</th>
        </tr>
      </thead>
      <tbody class="tb-investment">
        <tr v-for="(form, fkey) in frm"
            :key="fkey">
          <td class="pl-1 py-0"
              scope="row">
            Salaire moyen et avantages associés à la fonction
          </td>
          <td class="pl-1 py-0"
              scope="row">
            Charges de personnel
          </td>
          <td class="pl-1 py-0"
              scope="row">
            {{form.c}}
          </td>
          <td class="td-yellow py-0">
            <input v-model="form.d"
                   type="number"
                   @change="$emit('charge', frm)" />
          </td>
          <td class="td-yellow py-0">
            <input v-model="form.e"
                   type="number"
                   @change="$emit('charge', frm)" />
          </td>
          <td class="td-yellow py-0">
            <input v-model="form.f"
                   type="number"
                   @change="$emit('charge', frm)" />
          </td>
          <td class="td-yellow py-0">
            <input v-model="form.g"
                   type="number"
                   @change="$emit('charge', frm)" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  name: "Table3",
  data: () => ({
    frm: [
      {
        a: "Salaire moyen et avantages associés à la fonction",
        b: "Charges de personnel",
        c: "Chef de gare",
        d: 0,
        e: 0,
        f: 0,
        g: 0
      },
      {
        a: "Salaire moyen et avantages associés à la fonction",
        b: "Charges de personnel",
        c: "Superviseur",
        d: 0,
        e: 0,
        f: 0,
        g: 0
      },
      {
        a: "Salaire moyen et avantages associés à la fonction",
        b: "Charges de personnel",
        c: "Caissier",
        d: 0,
        e: 0,
        f: 0,
        g: 0
      },
      {
        a: "Salaire moyen et avantages associés à la fonction",
        b: "Charges de personnel",
        c: "Maintenancier",
        d: 0,
        e: 0,
        f: 0,
        g: 0
      },
      {
        a: "Salaire moyen et avantages associés à la fonction",
        b: "Charges de personnel",
        c: "Chargé clientèle",
        d: 0,
        e: 0,
        f: 0,
        g: 0
      },
      {
        a: "Salaire moyen et avantages associés à la fonction",
        b: "Charges de personnel",
        c: "Commercial",
        d: 0,
        e: 0,
        f: 0,
        g: 0
      },
      {
        a: "Salaire moyen et avantages associés à la fonction",
        b: "Charges de personnel",
        c: "Chauffeur",
        d: 0,
        e: 0,
        f: 0,
        g: 0
      },
      {
        a: "Salaire moyen et avantages associés à la fonction",
        b: "Charges de personnel",
        c: "Recouvrement",
        d: 0,
        e: 0,
        f: 0,
        g: 0
      },
      {
        a: "Salaire moyen et avantages associés à la fonction",
        b: "Charges de personnel",
        c: "Logistique",
        d: 0,
        e: 0,
        f: 0,
        g: 0
      },
      {
        a: "Salaire moyen et avantages associés à la fonction",
        b: "Charges de personnel",
        c: "Autres charges",
        d: 0,
        e: 0,
        f: 0,
        g: 0
      },
    ]
  }),
  // created() {
  //   var line = {
  //     a: "Salaire moyen et avantages associés à la fonction",
  //     b: "Charges de personnel",
  //     c: "",
  //     d: "",
  //     e: "",
  //     f: "",
  //     g: ""
  //   }
  //   this.frm.push(line)
  // },
  watch: {
    lineNumber() {
      // console.log(this.lineNumber)
      this.addLine()
    },
    pop() {
      if (this.pop == true) {
        this.delLine()
      }
    }
  },
  props: ["lineNumber", "pop"],
  methods: {
    addLine() {
      var rest = this.lineNumber - this.frm.length
      for (var index = 0; index < rest; index++) {
        var line = {
          a: "Salaire moyen et avantages associés à la fonction",
          b: "Charges de personnel",
          c: "",
          d: "",
          e: "",
          f: "",
          g: ""
        }
        this.frm.push(line)
      }
      // console.log(this.frm)
    },
    delLine() {
      if (this.frm.length > 1) {
        this.frm.pop()
        this.$emit("charge", this.frm)
        this.$emit("modif", false)
      }
      // console.log(this.frm)
    }
  }
}
</script>
