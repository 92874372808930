<template>
  <div class="col-10 mr-0 pr-0">
    <table class="table table-bordered table-investment">
      <thead class="th-investment">
        <tr>
          <th width="16%">CATEGORIE 1</th>
          <th width="16%">CATEGORIE 1.2</th>
          <th width="25%">DESIGNATION</th>
          <th class="text-center"
              width="15%">MONTANT INITIALE</th>
          <th class="text-center">ACTUALISATION</th>
          <th class="text-center py-1">AVENANT/NOUVELLE COMMANDE</th>
        </tr>
      </thead>
      <tbody class="tb-investment">
        <tr v-for="(form, fkey) in frm"
            :key="fkey">
          <td class="pl-0 py-0"
              scope="row">
            <select name=""
                    id=""
                    v-model="form.a"
                    @change="$emit('charge', frm)">
              <option value="Situation financière des travaux de construction">Situation financière des travaux de construction</option>
              <option value="Salaire moyen et avantages associés à la fonction">Salaire moyen et avantages associés à la fonction</option>
              <option value="Coût moyen d'acquisition des équipements (Matériels, mobiliers, aménagements, logistique...)"
              >Coût moyen d'acquisition des équipements (Matériels, mobiliers, aménagements, logistique...)</option
              >
              <option value="Charges d'exploitation autre que les salaires">Charges d'exploitation autre que les salaires</option>
              <option value="Ressources additionnelles">Ressources additionnelles</option>
            </select>
          </td>
          <td class="pl-0 py-0"
              scope="row">
            <select name=""
                    id=""
                    v-model="form.b"
                    @change="$emit('charge', frm)">
              <option value="Coût des travaux de génie civil">Coût des travaux de génie civil</option>
              <option value="Equipements de postes de péage, pièces de rechange">Equipements de postes de péage, pièces de rechange</option>
              <option value="Autres équipements et matériels">Autres équipements et matériels</option>
              <option value="Charges de personnel">Charges de personnel</option>
              <option value="Véhicules">Véhicules</option>
              <option value="Ordinateurs">Ordinateurs</option>
              <option value="Téléphones">Téléphones</option>
              <option value="Mobilier">Mobilier</option>
              <option value="Autres charges annuelles">Autres charges annuelles</option>
              <option value="Entretien courant">Entretien courant</option>
              <option value="Provisions pour entretien périodique nécessaire">Provisions pour entretien périodique nécessaire</option>
              <option value="Provisions pour entretien périodique (part du FER)">Provisions pour entretien périodique (part du FER)</option>
            </select>
          </td>
          <td class="pl-0 py-0 td-green"
              scope="row"><input v-model="form.c"
                                 type="text"
                                 @change="$emit('charge', frm)" /></td>
          <td class="td-yellow py-0">
            <input v-model="form.d"
                   type="number"
                   @change="$emit('charge', frm)" />
          </td>
          <td class="td-yellow py-0">
            <input v-model="form.e"
                   type="number"
                   @change="$emit('charge', frm)" />
          </td>
          <td class="td-yellow py-0">
            <input v-model="form.f"
                   type="number"
                   @change="$emit('charge', frm)" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  name: "Table1",
  data: () => ({
    frm: []
  }),
  created() {
    var line = {
      a: "",
      b: "",
      c: "",
      d: "",
      e: "",
      f: "",
    }
    this.frm.push(line)
  },
  watch: {
    lineNumber() {
      this.addLine()
    },
    pop() {
      if (this.pop == true) {
        this.delLine()
      }
    }
  },
  props: ["lineNumber","pop"],
  methods: {
    addLine() {
      var rest = this.lineNumber - this.frm.length
      for (var index = 0; index < rest; index++) {
        var line = {
          a: "",
          b: "",
          c: "",
          d: "",
          e: "",
          f: "",
        }
        this.frm.push(line)
      }
    },
    delLine() {
      if (this.frm.length > 1) {
        this.frm.pop()
        this.$emit("charge", this.frm)
        this.$emit("modif", false)
      }
      // console.log(this.frm)
    }
  }
}
</script>
