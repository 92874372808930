<template>
  <div class="bp main_container">
    <div class="row mb-3">
      <div class="text-left col-12">
        <router-link :to="'/analyses/board'">
          Analyses >
        </router-link>
        <router-link :to="'/analyses/bplan'">
          Business plan >
        </router-link>
        <span>Ajouter business plan</span>
      </div>
    </div>
    <div>
      <h3 class="text-center border border-1">AJOUTER BUSINESS PLAN</h3>
    </div>
    <h4>Investissements</h4>
    <div class="row row-investment">
      <Table1 :lineNumber="number" />
    </div>
    <div class="row mb-3">
      <button type="button"
              @click="upgrade(0)"
              class="btn btn-f-blue">+</button>
    </div>
    <div class="row row-investment">
      <Table2 :lineNumber="number[1]" />
    </div>
    <div class="row mb-3">
      <button type="button"
              @click="upgrade(1)"
              class="btn btn-f-blue">+</button>
    </div>
    <div class="row row-investment">
      <Table3 :lineNumber="number[2]" />
    </div>
    <div class="row mb-3">
      <button type="button"
              @click="upgrade(2)"
              class="btn btn-f-blue">+</button>
    </div>
    <div class="row pt-3 mx-auto">
      <router-link :to="{ name: 'Resultats' }"
                   class="btn btn-info btn-lg btn-block">
        Valider
      </router-link>
    </div>
  </div>
</template>
<style scoped>
@import "./businessPlan.css";
</style>
<script>
import Table1 from "./InvestmentTable1"
import Table2 from "./InvestmentTable2"
import Table3 from "./InvestmentTable3"
export default {
  name: "Investissement",
  components: {
    Table1,
    Table2,
    Table3
  },
  data:()=>({
    number:[1,1,1,1,1,1]
  }),
  methods:{
    upgrade(key){
      this.number[key]+=1
      // console.log(this.number)
    }
  }
}
</script>
